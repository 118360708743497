/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    em: "em",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Kątik nutryniau sakinį, kuriame aiškinu, kad turiu abejonių dėl\nataskaitos gyvavimo prasmės ir tada primečiau, kad nu visgi tai čia toks\nlabiau grynesnis blog’as, nei mano kiti blog’ai. Tai viskas čia\nnormaliai."), "\n", React.createElement(_components.p, null, "Savaitė buvo pradėta su fanfarom mano gyvenime prilyginamu reiškiniu,\nkur mane ištempė į spektaklį.\n„", React.createElement(_components.a, {
    href: "https://dramosteatras.lt/lt/spektaklis/perdegimas/"
  }, "Perdegimas"), "“\nvadinasi, kuriame yra du Mariai ir Stakelė. Šiaip tai man visokių baimių\niškart pridėjo mano durnas kritikavimo užsiėmimas, kur aš iškart\nužsiparinau dėl „trijų šokėjų tandemo“ frazės, kuri naudojama jų\nspektaklio aprašyme. Kad klaida būtų pataisyta dar nieko nedariau ir\nvelnias žino ar kada nors darysiu. Suprantu, kad būtų gerai, bet ", React.createElement(_components.em, null, "c’est\nla vie"), ". O spektaklis tai man labai patiko ir sukėlė daug viduj visokio\nšūdo. Nu to šūdo buvo jau atsineštinio tokio, kur iškart kilt pradėjo\nviduj ir tada reikėjo vienu metu tvarkytis ir su šūdu iš vidaus, ir su\nšūdu iš išorės. „Šūdas“ tai čia ne iš nepasitenkinimo – čia geras šūdas,\nkaip amerikonai sako."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Projektas, apie kurį rašiau praeitam, kažkaip tai truputšiuką numirė, ir\nvisą savaitę jau žiūrėjau YouTube’us ir žaidžiau žaidimukus. Čiuju\nviskas OK, nes aš į šitus reikalus visada su moraliniu nuosmūkiu vistiek\nsusisuku. Tai taip gavosi, kad susirgau. Nieko, pasveiksiu."), "\n", React.createElement(_components.p, null, "Btw, „nuosmūkis“, vlkk sako, galima rašyti abejaip, seniukai."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
